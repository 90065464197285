.sidebar_item {
  cursor: pointer;
  position: relative;
}

.sidebar_item::before {
  content: '';
  position: absolute;
  background-color: rgba(0, 187, 141, 0.1);
  border-radius: 25px;
  top: 0;
  right: 100%;
  bottom: 0;
  left: 0;
  transition: right .3s linear;
}

.sidebar_item:not(.not_animation):hover::before {
  right: 0;
}

.settings_modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999 !important;
  background-color: rgba(51,51,51,.16);
}

.sidebar-menu-item {
  height: 60px;
  cursor: pointer;
  display: flex;
  padding-left: 20px;
  align-items: center;
}

.sidebar-menu-item:hover {
  background-color: #F2F2F2;
}