.angie-logged-recharge-form-totals-content {
    display: flex;
    flex-direction: column;
}

@media (min-width: 919px) {
    .angie-logged-recharge-form-totals-content {
        display: flex;
        flex-direction: row;
        width: 400px;
        justify-content: space-between;
    }
}