.angie-logged-integration-form {
    display: flex;
    flex-direction: column;
}

@media (min-width: 919px) {
    .angie-logged-integration-form {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        max-width: 600px;
        margin-left: 60px;
    }
}
