.angie-logged-bank-account-affiliation {
    display: flex;
    flex-direction: column;
}

@media (min-width: 919px) {
    .angie-logged-bank-account-affiliation {
        display: flex;
        flex-direction: column;
        width: 489px;
    }
}

@media (min-width: 1080px) {
    .angie-logged-bank-account-affiliation {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 700px;
    }

    .angie-logged-bank-account-affiliation-form {
        width: 500px;
    }
}
