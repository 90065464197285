@media (min-width: 919px) {
  /* * {
    scrollbar-width: thin;
  } */

  ::-webkit-scrollbar {
    width: 6px;
    height: 5px;
  }

  ::-webkit-scrollbar-track {
    /* background: #F2F2F2; */
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #E7E7E7;
    border-radius: 24px;
    /* border: 1px solid transparent;
    -moz-border-image: linear-gradient(to bottom, #005FEE 0%, #00BB8D 100%) 1 1;
    -webkit-border-image: linear-gradient(to bottom, #005FEE 0%, #00BB8D 100%) 1 1;
    -o-border-image: linear-gradient(to bottom, #005FEE 0%, #00BB8D 100%) 1 1;
    border-image: linear-gradient(to bottom, #005FEE 0%, #00BB8D 100%) 1 1; */
  }
}

/* Change autocomplete styles in WebKit */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  box-shadow: 0 0 0px 1000px #FFF inset;
  -webkit-box-shadow: 0 0 0px 1000px #FFF inset;
  padding-top: 1px !important;
  margin-top: 10px;
}

textarea:focus-visible {
  outline: none;
}

input {
  outline: none;
  border: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Lato-Regular";
  src: url("../alejandra/resources/assets/fonts/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato-Bold";
  src: url("../alejandra/resources/assets/fonts/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato-Black";
  src: url("../alejandra/resources/assets/fonts/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato-Medium";
  src: url("../alejandra/resources/assets/fonts/Lato-Medium.ttf");
}

@font-face {
  font-family: "VariableBlack";
  src: url("wallet/resources/assets/fonts/VariableBlack.ttf");
}

input {
  font-family: "Lato-Regular";
  background-color: transparent;
  font-size: 14px;
}

.iput-container {
  background-color: #F2F2F2;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #F2F2F2;
}

.iput-container-error {
  background-color: #F2F2F2;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-color: #F2994A;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 20px solid #FFFFFF;
}

.angie-content-image {
  display: none;
  flex: auto;
}

.angie-auth-content {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.angie-auth-content-menu {
  width: 100%;
  height: 100%;
  background-color: #FFFFFF;
}

.angie-auth-footer {
  width: 100%;
}

.angie-logged-content {
  display: none;
}

.angie-logged-dashboard {
  width: 100%;
}

.angie-logged-transfer {
  width: 100%;
}

.angie-logged-pushnotifications {
  width: 100%;
}

.alejandra-logged-content {
  display: block;
}

/*
 *  Resolución de Angie
 */
@media (min-width: 919px) {

  .angie-logged-dashboard {
    width: 489px;
    padding-left: 60px;
  }

  .angie-logged-pushnotifications {
    width: 489px;
    padding-left: 60px;
  }

  .angie-logged-transfer {
    width: 489px;
    padding-left: 60px;
  }

  .alejandra-logged-content {
    display: none;
  }

  .angie-logged-content {
    display: block;
  }

  .angie-content-image {
    display: block;
    flex: auto;
  }

  .angie-auth-content {
    width: 336px;
    height: 100%;
    padding-left: 120px;
    padding-right: 144px;
  }

  .angie-auth-content-menu {
    width: 600px;
    height: 100%;
  }

  .angie-auth-footer {
    width: 336px;
    padding-left: 120px;
    padding-right: 144px;
    position: fixed;
    bottom: 0;
  }
}

@media (max-width: 919px) {

  iframe#iframe_vita_card {
    margin-left: -15px;
    width: 145%;
    margin-bottom: -60px;
    zoom: 0.75;
    -ms-zoom: 0.75;
    transform: scale(0.75);
    -moz-transform: scale(0.75);
    transform-origin: 0 0;
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.75);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.75);
    -webkit-transform-origin: 0 0;
  }
}