$angie_break: 919px;

$breakpoint_xs: 920px;
$breakpoint_s: 1280px;
$breakpoint_m: 1440px;
$breakpoint_l: 1920px;

$container_xs: 900px;
$container_s: 1260px;
$container_m: 1420px;
$container_l: 1521px;

$sidebar_xs: 210px;
$sidebar_s: 288px;
$sidebar_m: 324px;
$sidebar_l: 348px;

.angie_container {
  display: flex;
  flex-direction: row;
  flex: 1;
  max-width: 100%;

  @media (min-width: $breakpoint_xs) and (max-width: ($breakpoint_s - 1)) {
    max-width: $container_xs;
  }

  @media (min-width: $breakpoint_s) and (max-width: ($breakpoint_m - 1)) {
    max-width: $container_s;
  }

  @media (min-width: $breakpoint_m) and (max-width: ($breakpoint_l - 1)) {
    max-width: $container_m;
  }

  @media (min-width: $breakpoint_l) {
    max-width: $container_l;
  }
}

.sidebar {
  display: flex;
  width: 100%;

  @media screen and (max-width: $angie_break) {
    display: none;
  }

  @media (min-width: $angie_break) and (max-width: $breakpoint_xs) {
    max-width: $sidebar_xs;
  }

  @media (min-width: $breakpoint_xs) and (max-width: ($breakpoint_s - 1)) {
    max-width: $sidebar_xs;
  }

  @media (min-width: $breakpoint_s) and (max-width: ($breakpoint_m - 1)) {
    max-width: $sidebar_s;
  }

  @media (min-width: $breakpoint_m) and (max-width: ($breakpoint_l - 1)) {
    max-width: $sidebar_m;
  }

  @media (min-width: $breakpoint_l) {
    max-width: $sidebar_l;
  }
}

.sidebar_padding {
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 72px);
  overflow: auto;

  @media (min-width: $breakpoint_xs) and (max-width: ($breakpoint_s - 1)) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media (min-width: $breakpoint_s) and (max-width: ($breakpoint_m - 1)) {
    padding-left: 38px;
    padding-right: 38px;
  }

  @media (min-width: $breakpoint_m) and (max-width: ($breakpoint_l - 1)) {
    padding-left: 56px;
    padding-right: 56px;
  }

  @media (min-width: $breakpoint_l) {
    padding-left: 66px;
    padding-right: 66px;
  }
}

.aside_container {
  overflow: auto;
}

.settings_container {
  padding-top: 18px;
  padding-right: 40px;
  padding-bottom: 18px;
  padding-left: 40px;

  @media (min-width: $angie_break) and (max-width: $breakpoint_xs) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media (min-width: $breakpoint_xs) and (max-width: ($breakpoint_s - 1)) {
    padding-left: 12px;
    padding-right: 12px;
  }

  @media (min-width: $breakpoint_s) and (max-width: ($breakpoint_m - 1)) {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.sidebar_padding::-webkit-scrollbar,
.aside_container::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}